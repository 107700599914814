<template>
  <div>
    <banner :title="title" :subtitle="subtitle"></banner>
    <head-nav></head-nav>
    <router-view></router-view>
  </div>
</template>

<script>
import HeadNav from "../components/faq/HeadNav.vue";
import Banner from "../components/NormalBanner.vue";
import { get } from "../plugins/axios.js";
export default {
  components: { Banner, HeadNav },
  data() {
    return {
      title: "",
      subtitle: "",
    };
  },
  mounted() {
    let encode = "ZXZX";
    this.$nextTick(function () {
      get(`/HomeApi/QueryItemDetailByEncode`, { encode: encode })
        .then((data) => {
          console.log(data);
          this.title = data.Name;
          this.subtitle = data.Attr3;
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
};
</script>

<style>
</style>