<template>
  <div class="sol-banner">
    <!-- <h1 v-text="title"></h1>
    <p v-text="subtitle"></p> -->
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle"],
};
</script>

<style lang="less" scoped>
.sol-banner {
  color: #fff;
  height: 300px;
  max-width: 1920px;
  margin: 0 auto;
  aspect-ratio: auto 1920 / 300;
  background: url("../assets/slider6.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  h1 {
    font-size: 3rem;
    font-weight: 400;
    margin: 0;
  }
  p {
    margin: 5px 0 0 0;
  }
}
</style>