<template>
  <div class="nav">
    <div
      class="nav-item"
      :class="$route.name === item.name ? 'actived' : ''"
      v-for="(item, idx) in navs"
      :key="idx"
      @click="handleNav(item)"
    >
      <span v-text="item.label"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navs: [
        {
          label: "全部",
          name: "faq-all",
        },
        {
          label: "公司新闻",
          name: "faq-news",
        },
        {
          label: "行业动态",
          name: "faq-trends",
        },
        {
          label: "媒体报道",
          name: "faq-report",
        },
      ],
    };
  },
  methods: {
    handleNav(item) {
      this.$router.push({ name: item.name });
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  background: #fff;
  display: grid;
  grid-template-columns: repeat(4, 150px);
  height: 60px;
  align-items: flex-end;
  justify-items: center;
  justify-content: center;

  grid-gap: 40px;
  .nav-item {
    cursor: pointer;
    padding-bottom: 12px;
    margin: 1px;
  }

  .nav-item:hover {
    color: #0077ff;
  }
  .actived {
    color: #0066ff;
    border: solid #0066ff;
    border-width: 0 0 1.5px 0;
    min-width: 80px;
    text-align: center;
  }
}
</style>